<script>
import Layout from "../../layouts/main";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      numeroIntegracion: "",
      reconocimientoAduanero: "",
      tipoPedimento: "",
      pedimento: "",
      remesasPresentadas: "",
      numeroAcuseValor: "",
      tipoOperacion: "",
      clavePedimento: "",
      datosIdentificacionVehiculo: "",
      cantidadMercancia: "",
      lineaCaptura: "",
      institucionBancaria: "",
      fechaHoraPago: "",
      numeroOperacionBancaria: "",
      numeroTransaccionSAT: "",
      clavePrevalidador: "",
      candados: "",
      responseUrl: "", // Para almacenar la URL de respuesta del servidor
      qrData: "", // Para almacenar los datos del QR
      pecem_domain: process.env.VUE_APP_PECEM_DOMAIN,
      cargando: false,
    };
  },
  computed: {
    qrCodeUrl() {
      return `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(this.responseUrl)}`;
    },
    formValido() {
      return (
        this.numeroIntegracion !== '' &&
        this.reconocimientoAduanero !== '' &&
        this.tipoPedimento !== '' &&
        this.pedimento !== '' &&
        this.numeroAcuseValor !== '' &&
        this.tipoOperacion !== '' &&
        this.clavePedimento !== '' &&
        this.cantidadMercancia !== '' &&
        this.lineaCaptura !== '' &&
        this.institucionBancaria !== '' &&
        this.fechaHoraPago !== '' &&
        this.numeroOperacionBancaria !== '' &&
        this.numeroTransaccionSAT !== '' &&
        this.clavePrevalidador !== ''
      );
    },
  },
  
  methods: {
    async crearCarpeta() {
      this.cargando = true; // Iniciar la carga

      try {
        const response = await fetch(`https://${this.pecem_domain}/app/qr/ce/faces/pages/mobile/connection/new_folder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            numeroIntegracion: this.numeroIntegracion,
            reconocimientoAduanero: this.reconocimientoAduanero,
            tipoPedimento: this.tipoPedimento,
            pedimento: this.pedimento,
            remesasPresentadas: this.remesasPresentadas,
            numeroAcuseValor: this.numeroAcuseValor,
            tipoOperacion: this.tipoOperacion,
            clavePedimento: this.clavePedimento,
            datosIdentificacionVehiculo: this.datosIdentificacionVehiculo,
            cantidadMercancia: this.cantidadMercancia,
            lineaCaptura: this.lineaCaptura,
            institucionBancaria: this.institucionBancaria,
            fechaHoraPago: this.fechaHoraPago,
            numeroOperacionBancaria: this.numeroOperacionBancaria,
            numeroTransaccionSAT: this.numeroTransaccionSAT,
            clavePrevalidador: this.clavePrevalidador,
            candados: this.candados,
          }),
        });

        const data = await response.json();
        this.responseUrl = data.url;

      } catch (error) {
        console.error('Error al enviar la solicitud:', error);
      } finally {
        this.cargando = false; // Finalizar la carga
      }
    },
    downloadQR() {
      const link = document.createElement("a");
      link.href = this.qrCodeUrl;
      link.download = "qrCode.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
<template>
    <Layout>
      <div class="col-xl-12">
        <h5 class="title-home">Pecem<span class="dot-home">.</span></h5>
        <form @submit.prevent="crearCarpeta" class="form-container">
          <div class="form-group">
            <label for="numeroIntegracion">Número de Integración:<span class="required">*</span></label>
            <input type="text" class="form-control" id="numeroIntegracion" v-model="numeroIntegracion"/>
          </div>
          <div class="form-group">
            <label for="reconocimientoAduanero">Reconocimiento Aduanero:<span class="required">*</span></label>
            <input type="text" class="form-control" id="reconocimientoAduanero" v-model="reconocimientoAduanero"/>
          </div>
          <div class="form-group">
            <label for="tipoPedimento">Tipo de Pedimento:<span class="required">*</span></label>
            <input type="text" class="form-control" id="tipoPedimento" v-model="tipoPedimento"/>
          </div>
          <div class="form-group">
            <label for="pedimento">Pedimento:<span class="required">*</span></label>
            <input type="text" class="form-control" id="pedimento" v-model="pedimento"/>
          </div>
          <div class="form-group">
            <label for="remesasPresentadas">Remesas Presentadas:<span class="required">*</span></label>
            <input type="text" class="form-control" id="remesasPresentadas" v-model="remesasPresentadas"/>
          </div>
          <div class="form-group">
            <label for="numeroAcuseValor">Número de Acuse Valor:<span class="required">*</span></label>
            <input type="text" class="form-control" id="numeroAcuseValor" v-model="numeroAcuseValor"/>
          </div>
          <div class="form-group">
            <label for="tipoOperacion">Tipo de Operación:<span class="required">*</span></label>
            <input type="text" class="form-control" id="tipoOperacion" v-model="tipoOperacion"/>
          </div>
          <div class="form-group">
            <label for="clavePedimento">Clave de Pedimento:<span class="required">*</span></label>
            <input type="text" class="form-control" id="clavePedimento" v-model="clavePedimento"/>
          </div>
          <div class="form-group">
            <label for="datosIdentificacionVehiculo">Datos de Identificación del Vehículo:<span class="required">*</span></label>
            <input type="text" class="form-control" id="datosIdentificacionVehiculo" v-model="datosIdentificacionVehiculo"/>
          </div>
          <div class="form-group">
            <label for="cantidadMercancia">Cantidad de Mercancía:<span class="required">*</span></label>
            <input type="text" class="form-control" id="cantidadMercancia" v-model="cantidadMercancia"/>
          </div>
          <div class="form-group">
            <label for="lineaCaptura">Línea de Captura:<span class="required">*</span></label>
            <input type="text" class="form-control" id="lineaCaptura" v-model="lineaCaptura"/>
          </div>
          <div class="form-group">
            <label for="institucionBancaria">Institución Bancaria:<span class="required">*</span></label>
            <input type="text" class="form-control" id="institucionBancaria" v-model="institucionBancaria"/>
          </div>
          <div class="form-group">
            <label for="fechaHoraPago">Fecha y Hora de Pago:<span class="required">*</span></label>
            <input type="text" class="form-control" id="fechaHoraPago" v-model="fechaHoraPago"/>
          </div>
          <div class="form-group">
            <label for="numeroOperacionBancaria">Número de Operación Bancaria:<span class="required">*</span></label>
            <input type="text" class="form-control" id="numeroOperacionBancaria" v-model="numeroOperacionBancaria"/>
          </div>
          <div class="form-group">
            <label for="numeroTransaccionSAT">Número de Transacción SAT:<span class="required">*</span></label>
            <input type="text" class="form-control" id="numeroTransaccionSAT" v-model="numeroTransaccionSAT"/>
          </div>
          <div class="form-group">
            <label for="clavePrevalidador">Clave Prevalidador:<span class="required">*</span></label>
            <input type="text" class="form-control" id="clavePrevalidador" v-model="clavePrevalidador"/>
          </div>
          <div class="form-group">
            <label for="candados">Candados:<span class="required">*</span></label>
            <input type="text" class="form-control" id="candados" v-model="candados"/>
          </div>
          <div class="form-group">
            <button v-if="!cargando" :disabled="!formValido || cargando" type="submit" class="btn button-client-form btn-primary">
              <span v-if="!cargando">CREAR NUEVO</span>
              <span v-else>
                <i class="fa fa-spinner fa-spin"></i> Cargando...
              </span>
            </button>
          </div>
        </form>
  
        <div v-if="responseUrl">
          <p style="padding-top:15px;">URL Factura Electrónica:<br> <a :href="responseUrl" target="_blank">{{ responseUrl }}</a></p>
          <img :src="qrCodeUrl" alt="QR Code" />
          <br><button style="margin-top:15px;" @click="downloadQR" class="btn btn-primary">Descargar QR</button>
        </div>
      </div>
    </Layout>
  </template>
  
  <style>
  .form-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .form-group {
    width: calc(33.33% - 20px); /* Tres columnas con un pequeño margen */
    margin-right: 10px;
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
  }
  
  .required {
    color: #247ba0;
  }
  
  .form-group:nth-child(3n) {
    margin-right: 0; /* Eliminar margen derecho para el último elemento de cada fila */
  }
  
  button.btn.button-client-form.btn-primary {
    margin-top: 30px;
  }
  </style>
  
